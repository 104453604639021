import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/PagePricing/Hero"
import Plan from "../components/PagePricing/Plan"
import SEO from "../components/seo"


const Page = () => (
    <Layout>
        <SEO title="Pricing" />
        <Hero />
        <Plan />
    </Layout>
)

export default Page
