import React, { useState } from 'react'
import LineIcon from '../../../styles/icons/line'
import PricingCards from '../../Pricing/cards'

function Pricing() {
    const [isMonthly, setIsMonthly] = useState(false)
    return (
        <section>
            <div className="container mx-auto pt-10 space-y-4">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center mx-auto">
                    <span className="bg-shape">One tool for your</span> <br />
                    whole workforce needs
                </h1>
                <p className="text-body-light text-sm lg:text-base md:lg:text-base text-center max-w-xl mx-auto">
                    Try Handdy with full features for 30 days to start increasing your workforce productivity. Cancel any time. No credit card required.
                </p>
                <div className="flex items-center space-x-4 justify-center flex-wrap">
                    <label className={!isMonthly && 'text-body-muted'} htmlFor="switch">Bill Monthly</label>
                    <input
                        defaultChecked={!isMonthly}
                        onChange={() => setIsMonthly(!isMonthly)}
                        id="switch"
                        name="switch"
                        type="checkbox" className="switch"></input>
                    <label className={isMonthly && 'text-body-muted'} htmlFor="switch">Bill Annually</label>
                    <p className="text-primary-dark font-bold text-base relative">
                        Save 15%
                            {!isMonthly &&
                            <LineIcon className="absolute -bottom-1" />
                        }
                    </p>
                </div>
            </div>
            <PricingCards />
        </section >
    )
}

export default Pricing
