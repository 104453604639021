import React from 'react'
import CheckIcon from '../../../styles/icons/check'
import XIcon from '../../../styles/icons/x'

function Remote() {
    return (
        <section className="lg:pt-28 md:pt-20 sm:pt-12 pt-4">
            <div className="container mx-auto">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center max-w-lg mx-auto lg:mb-12 md:mb-8 mb-4">
                    Full Plan Comparison
                </h1>
                <div className="grid gap-2">
                    <table className="rounded-t-lg m-5  mx-auto bg-gray-200 text-gray-800 w-full table-fixed">
                        <tbody>
                            <tr className="text-heading-dark shadow-inner bg-white-300 text-base lg:text-xl md:text-lg">
                                <th className="text-left px-4 py-3 w-4/12">Time & Attendance Management</th>
                                <th className="text-center px-4 py-3">Free</th>
                                <th className="text-center px-4 py-3">Premium</th>
                                <th className="text-center px-4 py-3">Enterprise</th>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Time and attendance tracking</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">One tap time tracking</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Work and break hours tracking</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Client/project/task tracking</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Who’s working today</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="rounded-t-lg m-5  mx-auto bg-gray-200 text-gray-800 w-full table-fixed">
                        <tbody>
                            <tr className="text-heading-dark shadow-inner bg-white-300 text-base lg:text-xl md:text-lg">
                                <th className="text-left px-4 py-3 w-4/12">Employee Monitoring</th>
                                <th className="text-center px-4 py-3">Free</th>
                                <th className="text-center px-4 py-3">Premium</th>
                                <th className="text-center px-4 py-3">Enterprise</th>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Website usage tracking</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Application usage tracking</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Keystroke monitoring</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Screenshot capture</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">IP restriction</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="rounded-t-lg m-5  mx-auto bg-gray-200 text-gray-800 w-full table-fixed">
                        <tbody>
                            <tr className="text-heading-dark shadow-inner bg-white-300 text-base lg:text-xl md:text-lg">
                                <th className="text-left px-4 py-3 w-4/12">Productivity Analytics</th>
                                <th className="text-center px-4 py-3">Free</th>
                                <th className="text-center px-4 py-3">Premium</th>
                                <th className="text-center px-4 py-3">Enterprise</th>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Productive employee charts</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Apps & websites usage by staff</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Productivity report by project</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Productivity report by category</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Productivity reports by team</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="rounded-t-lg m-5  mx-auto bg-gray-200 text-gray-800 w-full table-fixed">
                        <tbody>
                            <tr className="text-heading-dark shadow-inner bg-white-300 text-base lg:text-xl md:text-lg">
                                <th className="text-left px-4 py-3 w-4/12">Productivity Analytics</th>
                                <th className="text-center px-4 py-3">Free</th>
                                <th className="text-center px-4 py-3">Premium</th>
                                <th className="text-center px-4 py-3">Enterprise</th>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Productive employee charts</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Suits office or work from home</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Works with or without internet</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Unlimited employees</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Centralized admin control</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Intuitive dashboard</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Desktop/cloud sync</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Works with Mac OS</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3 text-body-muted">Linux - Coming soon</td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="rounded-t-lg m-5  mx-auto bg-gray-200 text-gray-800 w-full table-fixed">
                        <tbody>
                            <tr className="text-heading-dark shadow-inner bg-white-300 text-base lg:text-xl md:text-lg">
                                <th className="text-left px-4 py-3 w-4/12">Custom Integrations</th>
                                <th className="text-center px-4 py-3">Free</th>
                                <th className="text-center px-4 py-3">Premium</th>
                                <th className="text-center px-4 py-3">Enterprise</th>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Custom software integrations</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                            <tr className="bg-gray-100 shadow-inner text-sm lg:text-lg">
                                <td className="px-4 py-3">Custom software development</td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><XIcon /></td>
                                <td className="px-4 py-3"><CheckIcon className="mx-auto" isGreen /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="grid scale-card pt-8 lg:grid-cols-3 md:grid-cols-3 max-w-5xl lg:justify-self-end md:justify-self-end grid-cols-1 gap-8 justify-items-center">
                        <div className="card max-w-xs bg-success-soft cursor-pointer min-w-1/4">
                            <h1 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                                Free
                            </h1>
                            <div className="flex items-center space-x-2">
                                <h1 className="font-semibold text-primary lg:text-3xl md:text-2xl sm:text-xl text-lg">$0</h1>
                            </div>
                            <span className="text-sm">/month per user</span>
                            <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                            <button className="focus:outline-none font-semibold text-white text-sm mt-6 h-10 rounded-md bg-primary min-w-full box-shadow">Get started</button>
                        </div>
                        <div className="card max-w-xs bg-primary cursor-pointer text-white min-w-1/4">
                            <h1 className=" font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                                Premium
                            </h1>
                            <div className="flex items-center space-x-2">
                                <h1 className="font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">$1.72</h1>
                            </div>
                            <span className="text-sm">/month per user</span>
                            <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                            <button className="focus:outline-none text-primary font-semibold text-sm mt-6 h-10 rounded-md bg-white min-w-full box-shadow">Get started</button>
                        </div>
                        <div className="card max-w-xs bg-info-soft cursor-pointer min-w-1/4">
                            <h1 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                                Enterprise
                            </h1>
                            <div className="flex items-center space-x-2">
                                <span className="text-sm">Let's talk</span>
                            </div>
                            <h1 className="font-semibold opacity-0 lg:text-3xl md:text-2xl sm:text-xl text-lg">Let’s talk</h1>
                            <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                            <button className="focus:outline-none font-semibold text-white text-sm mt-6 h-10 rounded-md bg-info-dark min-w-full box-shadow">Contact Sales</button>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-between lg:mt-32 md:mt-24 sm:mt-16 mt-8">
                        <div className="max-w-xl">
                            <h6 className="heading">Not sure what to choose? </h6>
                            <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                                Let us help you to decide the right plan for you
                            </h1>
                        </div>
                        <img src="/graphics-5.svg"></img>
                    </div>
                    <ul className="grid gap-11 lg:grid-cols-3 md:grid-cols-3 ">
                        <li className="pt-4">
                            <h4 className="pb-2 rounded-sm text-heading-dark text-lg font-semibold border-b-2 rounded-t-none border-primary-light ">Free plan is for you if...</h4>
                            <p className="pt-4 text-body-muted text-base">Lorem ipsum dolor sit amet, consectetur adic elit, sed do eiusmod tempor incididunt utlabor et dolore magna aliqua. Massa tempor necfat nisl pretium. In iaculis nunc sed augue lacerra vitae congue eu. At tellus at urnacodimentum.</p>
                        </li>
                        <li className="pt-4">
                            <h4 className="pb-2 rounded-sm text-heading-dark text-lg font-semibold border-b-2 border-primary ">Premium plan is for you if...</h4>
                            <p className="pt-4 text-body-muted text-base">Lorem ipsum dolor sit amet, consectetur adic elit, sed do eiusmod tempor incididunt utlabor et dolore magna aliqua. Massa tempor necfat nisl pretium. In iaculis nunc sed augue lacerra vitae congue eu. At tellus at urnacodimentum.</p>
                        </li>
                        <li className="pt-4">
                            <h4 className="pb-2 rounded-sm text-heading-dark text-lg font-semibold border-b-2 border-info-dark">Enterprise plan is for you if...</h4>
                            <p className="pt-4 text-body-muted text-base">Lorem ipsum dolor sit amet, consectetur adic elit, sed do eiusmod tempor incididunt utlabor et dolore magna aliqua. Massa tempor necfat nisl pretium. In iaculis nunc sed augue lacerra vitae congue eu. At tellus at urnacodimentum.</p>
                        </li>
                    </ul>
                    <div className="grid bg-secondary gap-3 lg:grid-cols-3 md:grid-cols-3 items-center justify-items-center lg:py-6 lg:px-0 p-4 rounded-xl lg:my-20 md:my-12 sm:my-8 my-4">
                        <h4 className="lg:text-lg md:text-lg text-base text-heading-dark font-bold">We're here to help</h4>
                        <p className="text-tiny lg:text-base md:text-base max-w-sm">
                            Would you like a free personalised demo to learn
                            how you can use Handdy at your company?
                    </p>
                        <button className="focus:outline-none font-semibold text-white text-sm h-10 rounded-md bg-primary px-5 box-shadow">Book your free demo now</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Remote
