import React from "react";

function Icon({ active = false, fillColor = "#0BAD4B" }) {
    if (active) {
        return (
            <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="none"
                viewBox="0 0 13 13"
            >
                <path
                    fill={fillColor}
                    fillRule="evenodd"
                    d="M6.906.109a.813.813 0 00-.812 0L1.219 3.015a.812.812 0 00-.407.704v5.562c0 .29.155.558.407.704l4.875 2.906a.812.812 0 00.812 0l4.875-2.906a.813.813 0 00.406-.704V3.719a.812.812 0 00-.406-.704L6.906.11z"
                    clipRule="evenodd"
                ></path>
            </svg >
        );
    } else {
        return (
            <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="none"
                viewBox="0 0 13 13"
            >
                <path
                    fill="#A6B7D4"
                    fillRule="evenodd"
                    d="M11.375 3.72L6.5.811 1.625 3.72v5.562L6.5 12.187l4.875-2.906V3.719zM6.906.108a.813.813 0 00-.812 0L1.219 3.015a.812.812 0 00-.407.704v5.562c0 .29.155.558.407.704l4.875 2.906a.812.812 0 00.812 0l4.875-2.906a.813.813 0 00.406-.704V3.719a.812.812 0 00-.406-.704L6.906.11z"
                    clipRule="evenodd"
                ></path>
            </svg>
        );
    }

}

export default Icon;
