import React from 'react'
import HexagonIcon from '../../styles/icons/hexagon'

export default function PricingCards() {
    return (
        <div className="grid scale-card pt-8 lg:grid-cols-3 md:grid-cols-3 max-w-5xl mx-auto grid-cols-1 gap-y-8 justify-items-center w-full">
            <div className="card max-w-xs bg-success-soft cursor-pointer grid justify-between">
                <h1 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                    Free
            </h1>
                <div className="flex items-center space-x-2">
                    <h1 className="font-semibold text-primary lg:text-3xl md:text-2xl sm:text-xl text-lg">$0</h1><span className="text-sm">/month per user</span>
                </div>
                <p className="text-sm">
                    Up to 2 users, with all the basics for businesses that are just getting started
            </p>
                <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                <ul className="space-y-1">
                    <li className="text-sm flex items-center"><HexagonIcon active />Time & Attandance Management</li>
                    <li className="text-sm text-body-muted flex items-center"><HexagonIcon />Employee Monitoring</li>
                    <li className="text-sm text-body-muted flex items-center"><HexagonIcon />Productivity Analytics</li>
                    <li className="text-sm text-body-muted flex items-center"><HexagonIcon />Custom Integrations</li>
                </ul>
                <button className="focus:outline-none font-semibold text-white text-sm mt-6 h-10 rounded-md bg-primary min-w-full box-shadow">Get started</button>
            </div>
            <div className="card max-w-xs bg-primary cursor-pointer text-white grid justify-between">
                <h1 className=" font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                    Premium
            </h1>
                <div className="flex items-center space-x-2">
                    <h1 className="font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">$1.72</h1><span className="text-sm">/month per user</span>
                </div>
                <p className="text-sm">
                    Organizations up to 500 employees, that need deep analysis of employee activity
            </p>
                <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                <ul className="space-y-1">
                    <li className="text-sm flex items-center"><HexagonIcon fillColor="white" active />Time & Attandance Management</li>
                    <li className="text-sm flex items-center"><HexagonIcon fillColor="white" active />Employee Monitoring</li>
                    <li className="text-sm flex items-center"><HexagonIcon fillColor="white" active />Productivity Analytics</li>
                    <li className="text-sm flex items-center"><HexagonIcon fillColor="white" active />Custom Integrations</li>
                </ul>
                <button className="focus:outline-none text-primary font-semibold text-sm mt-6 h-10 rounded-md bg-white min-w-full box-shadow">Get started</button>
            </div>
            <div className="card max-w-xs bg-info-soft cursor-pointer grid justify-between">
                <h1 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">
                    Enterprise
            </h1>
                <p className="text-sm">
                    Organizations with 500+ employees and need deep analysis of employee activity, 24/7 excellent client support
            </p>
                <div className="my-4 bg-primary-border opacity-10 h-0.7 rounded-md" />
                <ul className="space-y-1">
                    <li className="text-sm flex items-center"><HexagonIcon fillColor="#4CAFD0" active />Time & Attandance Management</li>
                    <li className="text-sm  flex items-center"><HexagonIcon fillColor="#4CAFD0" active />Employee Monitoring</li>
                    <li className="text-sm  flex items-center"><HexagonIcon fillColor="#4CAFD0" active />Productivity Analytics</li>
                    <li className="text-sm  flex items-center"><HexagonIcon fillColor="#4CAFD0" active />Custom Integrations</li>
                </ul>
                <button className="focus:outline-none font-semibold text-white text-sm mt-6 h-10 rounded-md bg-info-dark min-w-full box-shadow">Contact Sales</button>
            </div>
        </div>
    )
}
