import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={70}
            height={5}
            viewBox="0 0 70 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                className="path"
                d="M1 4S32.021-.138 69 1.432"
                strokeWidth={1.667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default SvgComponent
